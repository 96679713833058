<template>
	<div class="main">
		<el-container>
			<MyHeader :userName="userName"></MyHeader>
			<div class="bo"></div>
			<Nav></Nav>
			<el-main>
				<div class="lorder">
					<div class="latestOrders" align="left">
						<div style="display: flex;justify-content: space-between;">
							<!-- <img alt="l" src="../assets/latestorders.png" width="300" /> -->
							<div class="btns" align="center">
								<el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm"
									class="demo-ruleForm">
									<!-- 开始日期 -->
									<el-date-picker style="width: 15%;margin-right: 0.825rem;"
										v-model="ruleForm.startTime" type="date" placeholder="选择开始日期"
										format="yyyy-MM-dd" value-format="yyyy-MM-dd">
									</el-date-picker>
									<!-- 结束日期 -->
									<el-date-picker style="width: 15%;margin-right: 0.825rem;"
										v-model="ruleForm.endTime" type="date" placeholder="选择结束日期" format="yyyy-MM-dd"
										value-format="yyyy-MM-dd">
									</el-date-picker>

									<!-- 订单状态 -->
									<el-select style="width: 16%;margin-right: 0.825rem;" v-model="ruleForm.status"
										clearable placeholder="请选择订单状态">
										<el-option v-for="item in statusList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
									<!-- 产品选择 -->
									<el-select style="width: 15%;margin-right: 0.825rem;" v-model="ruleForm.productId"
										clearable placeholder="请选择产品">
										<el-option v-for="item in productList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>

									<!-- 患者编号和订单编号 -->
									<el-input style="width: 23%;margin-right: 0.0625rem;" placeholder="请输入患者编号/订单编号"
										v-model="ruleForm.query" clearable>
									</el-input>
									<el-button type="primary"
										style="width: 10%;background-color: #2B9BB3;border-color: #2B9BB3;"
										@click="searchOrder">搜索</el-button>
								</el-form>
							</div>
						</div>

						<div class="tablescon">
							<el-table height="350" :data="tableData" stripe style="width: 100%;">
								<el-table-column prop="orderNum" label="订单ID号" width="180">
									<template slot-scope="scope">
										<span v-if="scope.row.orderNum==null">暂无</span>
										<span v-if="scope.row.orderNum!=null">{{scope.row.orderNum}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="oid" label="订单ID号" width="180" v-if="false">
								</el-table-column>
								<el-table-column prop="patientNum" label="患者编号" width="180">
								</el-table-column>
								<el-table-column prop="patientNum" label="订单类型">
								</el-table-column>
								<el-table-column prop="productId" label="产品类型">
									<template slot-scope="scope">
										<span v-if="scope.row.productId==1">瓠犀</span>
										<span v-if="scope.row.productId==2">优诗美</span>
									</template>
								</el-table-column>
								<el-table-column prop="status" label="订单状态">
									<template slot-scope="scope">
										<span v-if="scope.row.status==0">创建(未完成)</span>
										<span v-if="scope.row.status==1">未审核</span>
										<span v-if="scope.row.status==2">审核未通过</span>
										<span v-if="scope.row.status==3">已确认,待付款</span>
										<span v-if="scope.row.status==4">已付款,制作中</span>
										<span v-if="scope.row.status==5">运输中</span>
										<span v-if="scope.row.status==6">已完成</span>
									</template>
								</el-table-column>
								<el-table-column prop="updatedTime" label="提交日期">
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<a v-if="scope.row.status==0||scope.row.status==1" href="javascript:void(0)"
											@click="handleDelete(scope.$index, scope.row)">删除</a>&nbsp;
										<a href="javascript:void(0)" @click="lookOrder(scope.row)">查看</a>&nbsp;
										<a v-if="scope.row.status==0||scope.row.status==1||scope.row.status==2" href="javascript:void(0)" @click="editOrder(scope.row)">编辑</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- 饼状图 -->
					<!-- <div class="chart">
						<div id="myChart" :style="{width: '370px', height: '280px'}">111</div>
					</div> -->
				</div>


			</el-main>
			<el-footer style="font-size: 0.875rem; color: #1196AB;">
				Copyright © www.techlion,.com.cn All Rights Reserved.<br>
				山东泰克莱恩 8008888888
			</el-footer>
		</el-container>

	</div>
</template>
<!-- 订单状态：0:创建（未完成），1:未审核，2:审核中，3:已确认，待付款，4:已付款，制作中，5:运输中，6:已完成 -->
<script>
	import MyHeader from '../components/MyHeader.vue'
	import Nav from '../components/Nav.vue'
	export default {

		data: function() {
			return {
				loginUserName: '张三',
				tableData: [],
				userName: '',
				doctorId: '0',
				ruleForm: {
					startTime: '',
					endTime: '',
					productId: '',
					status: '',
					query: ''
				},
				rules: {},
				statusList: [{
					value: 0,
					label: "创建(未完成)"
				}, {
					value: 1,
					label: "未审核"
				}, {
					value: 2,
					label: "审核未通过"
				}, {
					value: 3,
					label: "已确认,待付款"
				}, {
					value: 4,
					label: "已付款,制作中"
				}, {
					value: 5,
					label: "运输中"
				}, {
					value: 6,
					label: "已完成"
				}],
				productList: [{
					value: 2,
					label: "优诗美"
				}, {
					value: 1,
					label: "瓠犀"
				}]
			}

		},
		components: {
			MyHeader,
			Nav
		},
		methods: {
			//查询所有订单
			getLatestOrder() {
				this.$axios.get("client/order/initOrderList", {
					params: {
						doctorId: this.doctorId
					}
				}).then(res => {
					console.log(res.data);
					this.tableData = res.data.data;
				}).catch(err => {
					console.log(err);
				});
			},
			//根据条件查询订单
			searchOrder() {
				this.ruleForm.doctorId = this.doctorId;
				console.log(this.ruleForm);
				this.$axios.post("client/order/queryOrderList", JSON.stringify(this.ruleForm), {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					console.log(res.data.data);
					this.tableData = res.data.data
				}).catch(err => {
					console.log(err);
				});
			},
			//设置饼状图
			// drawLine() {
			// 	var _this=this;
			// 	this.$axios.get("./tongjiorder.json").then(res=>{
			// 		this.tongjiorder=res.data;
			// 		console.log(this.tongjiorder);
			// 		console.log(this.$axios);
			// 		this.chartColumn = this.$echarts.init(document.getElementById('myChart'));
			// 		this.chartColumn.setOption({
			// 			title: {
			// 				text: '订单完成情况',
			// 				subtext: '',
			// 				left: 'center'
			// 			},
			// 			tooltip: {
			// 				trigger: 'item'
			// 			},
			// 			legend: {
			// 				orient: 'vertical',
			// 				left: 'left'
			// 			},
			// 			series: [{
			// 				name: 'Access From',
			// 				type: 'pie',
			// 				radius: '50%',
			// 				data:_this.tongjiorder,
			// 				emphasis: {
			// 					itemStyle: {
			// 						shadowBlur: 10,
			// 						shadowOffsetX: 0,
			// 						shadowColor: 'rgba(0, 0, 0, 0.5)'
			// 					}
			// 				}
			// 			}]
			// 		});
			// 	}).catch(err=>{
			// 		console.log(err);
			// 	});
			// },
			//删除
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			//添加新订单
			addNewOrder() {
				this.$router.push("/addOrder/orderone");
			},
			//编辑订单
			editOrder(row) {
				console.log(row);
				sessionStorage.setItem("oid", row.oid)
				this.$router.push("/addOrder/orderone");
			},
			//查看具体某个订单
			lookOrder(row){
					sessionStorage.setItem("oid",row.oid);
					this.$router.push("/lookorder");
			},
			//删除订单
			handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get("/client/order/delOrder",{
						params:{
							oid:row.oid
						}
					}).then(res=>{
						if(res.data.code==200){
							this.tableData.splice(index, 1);
							this.$message({
								type: 'success',
								message: '删除成功!',
								duration: 900
							});
						}else{
							this.$message({
								type: 'error',
								message: '删除失败',
								duration: 900
							});
						}
					}).catch(err => {
						console.log(err);
						this.$message({
							type: 'error',
							message: '请联系管理员',
							duration: 900
						});
					});
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
						duration: 900
					});
				});

			}
		},
		mounted: function() {
			//加载最新订单
			this.getLatestOrder();
			//画饼状图
			//this.drawLine();
			//获取当前登录的用户名
			let dname = JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			let doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			this.userName = dname;
			this.doctorId = doctorId;
			//设置location
			sessionStorage.setItem("location", "orderone");
			sessionStorage.removeItem("oid");
		}
	}
</script>

<style scoped="scoped">
	.main {
		width: 100%;
		height: 100%;
		/* background-image: linear-gradient(to bottom, white 20%, #58C9D5, #1196AB); */
		/* background-image: linear-gradient(to bottom, white 20%, #9BEDEB, #28D9D5); */
		background-color: whitesmoke;
		
	}

	.el-container {
		height: 100%;
		
	}

	.el-header {
		line-height: 3.75rem;
		background-image: url(../assets/logo@2x.png);
		background-repeat: no-repeat;
		background-position: 1.25rem 0.625rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.bo {
		width: 100%;
		height: 0.1125rem;
		background-image: linear-gradient(to right, #58C9D5);
	}

	.header1,
	.header2,
	.header3,
	.header4 {
		display: inline-block;
		margin-left: 1.25rem;
	}

	.header4 {
		height: 2.1875rem;
	}

	.loginName {
		color: #2B9BB3;
		font-weight: bold;
	}

	/* 最近订单 */
	.latestOrders {
		width: 90%;
		margin: 0px auto;
		margin-top: 1.25rem;
		
	}

	tbody {
		background-color: red !important;
	}

	.lorder {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-image: linear-gradient(to bottom,white,lightgray);
		border-radius: 0.9375rem;
		width: 80%;
		height: 100%;
		margin: 0px auto;
		padding-bottom: 0.625rem;
		
	}

	/* 添加订单 */
	.btns {
		margin-bottom: 0.5125rem
	}

	.tablescon {
		height: 80%;
	}
	.el-main{
		overflow-y: hidden !important;
	}
</style>
